import indexSearchForm from './models/indexSearchForm'
import indexSearchResults from './models/indexSearchResults'
import heldDefinitions from './models/heldDefinitions'
import translations from './models/translations'
import headings from './models/headings'

import { init } from '@rematch/core'
const store = init({
  models: {
      indexSearchForm,
      indexSearchResults,
      heldDefinitions,
      translations,
      headings
  }
})

export default store
