import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import DefinitionSearchResult from './nodes'

export class IndexResults extends Component {
    static defaultProps = {
        results: [],
        invalidated: false,
        fetching: false,
        searchParams: {},
        error: null,
        selectedIds: [],
        onToggleResult: Function.prototype,
    }

    static propTypes = {
        results: PropTypes.array.isRequired,
        invalidated: PropTypes.bool,
        fetching: PropTypes.bool,
        searchParams: PropTypes.object,
        error: PropTypes.oneOfType([PropTypes.any, PropTypes.object]),
        selectedIds: PropTypes.arrayOf(PropTypes.string),
        onToggleResult: PropTypes.func,
    }

    render() {
        const headLanguage = this.props.searchParams.language,
              listLanguage = headLanguage === 'spanish' ? 'english' : 'spanish'
        return (
            <div>{
                this.props.results.map((result, inx) => (
                    <DefinitionSearchResult
                        searchResult={result}
                        key={inx}
                        headLanguage={ headLanguage }
                        listLanguage={ listLanguage }
                        onToggleResult={this.props.onToggleResult}
                        selectedIds={this.props.selectedIds} />
                ))
            }</div>
        )
    }
}

const stateMapper = state => ({
    results: state.indexSearchResults.results,
    invalidated: state.indexSearchResults.invalidated,
    fetching: state.indexSearchResults.fetching,
    searchParams: state.indexSearchResults.searchParams,
    selectedIds: state.heldDefinitions.definitionIds,
    error: state.indexSearchResults.error
})

const dispatchMapper = ({heldDefinitions}) => ({
    onToggleResult: (result) => {
        heldDefinitions.toggleDefinition(result)
    },
})

export default connect(stateMapper, dispatchMapper)(IndexResults)

