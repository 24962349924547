import React from 'react'
import { Glyphicon } from 'react-bootstrap'

const
    E_NO_INTERNET_CONNECTION = 'Al parecer no hay conexión a Internet'


export const BaseNotice = (props) => (
    <div className={`text-center notice notice-${props.type || 'default'}`}>
        { props.children }
    </div>
)

export const IndexSearchBlankState = (props) => (
    <BaseNotice>
        <h1>
            <Glyphicon glyph="flash" />
        </h1>

        <h3>Espacio para hacer búsquedas en los índices oficiales</h3>
        <h5>
            Elija la configuración de búsqueda en la parte superior. <br/>
            Puede buscar más de una indicación separándolas con punto y coma.
        </h5>
    </BaseNotice>
)

export const ClassHeadingSearchBlankStat = (props) => (
    <BaseNotice>
        <h1>
            <Glyphicon glyph={props.icon || "flash"} />
        </h1>

        <h3>Espacio para consultar los encabezados oficiales de clases</h3>
        <h5>
            Elija una clase para ver su encabezado. <br/>
            También puede buscar un artículo o servicio en los encabezados.
        </h5>
    </BaseNotice>
)

export const RequestErrorNotice = (props) => (
    <BaseNotice type="error">
        <h1>
            <Glyphicon glyph="exclamation-sign" />
        </h1>

        <h4>{props.message || E_NO_INTERNET_CONNECTION}</h4>
    </BaseNotice>
)
