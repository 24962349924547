import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Panel, ListGroup, ListGroupItem } from 'react-bootstrap'
import { Label, Button, Glyphicon } from 'react-bootstrap'
import { Dropdown, MenuItem } from 'react-bootstrap'
import { SOURCES_MAP } from './../../../constants'

// Force import of node stylesheet
// eslint-disable-next-line
import _ from './styles.css'

export const BackendResultPropType = PropTypes.shape({
    id: PropTypes.string,
    source: PropTypes.string,
    classNumber: PropTypes.number,
    english: PropTypes.array,
    spanish: PropTypes.array,
    french: PropTypes.array,
})

export const SourceMap = {
    "niza11": "N11",
    "niza10": "N10",
    "niza9": "N9",
}

export const ResultHeader = (props) => (
    <div>{props.children}</div>
)

export class DefinitionToggleBtn extends Component {
    static defaultProps = {
        minusIconOnNormal: false,
    }

    static propTypes = {
        toggled: PropTypes.bool,
        minusIconOnNormal: PropTypes.bool,
    }

    constructor(props) {
        super(props)
        this.state = {
            hover: false
        }
    }

    render() {
        const
            hover = this.state.hover,
            toggled = this.props.toggled,
            minusIconOnNormal = this.props.minusIconOnNormal,
            useDangerIcon = ((hover || minusIconOnNormal) && toggled)

        const hoverStateIcon = {
            true: toggled ? "minus" : "plus",
            false: toggled ? (minusIconOnNormal ? "minus" : "ok" ): "plus"
        }

        return (
            <Button
                bsStyle="link"
                bsSize="xs"
                onClick={this.props.onClick}
                onMouseEnter={() => this.setState({hover: true})}
                onMouseLeave={() => this.setState({hover: false})}>
                <Glyphicon className={useDangerIcon ? 'text-danger' : ''}
                           glyph={ hoverStateIcon[hover] } />
            </Button>
        )
    }
}

export class DefinitionText extends Component {
    static defaultProps = {
        bsSize: 'medium',
        textArray: [],
        displayDropdown: true,
        onChooseText: Function.prototype,
    }

    static propTypes = {
        textArray: PropTypes.arrayOf(PropTypes.string),
        onChooseText: PropTypes.func,
        displayDropdown: PropTypes.bool,
    }

    constructor(props) {
        super(props)
        this.onToggleText = this.onToggleText.bind(this)
        this.renderMenu = this.renderMenu.bind(this)
        this.state = {}
    }

    onToggleText(selectedInx) {
        const items = this.props.textArray
        const displayText = items.length ? items[selectedInx] : ''
        this.setState({selectedInx, displayText})

        if (this.props.onChooseText) {
            this.props.onChooseText(selectedInx, displayText)
        }
    }

    render() {
        // const displayText = this.state.displayText
        let displayText = this.props.textArray[
            this.state.selectedInx || 0
        ]
        if (!this.props.displayDropdown) {
            displayText = this.props.textArray.join(' / ')
        }

        if (this.props.displayDropdown && this.props.textArray.length > 1) {
            return this.renderMenu(displayText)
        } else {
            return this.renderTextSpan(displayText)
        }
    }

    renderTextSpan(displayText) {
        return (
            <span id="display-value">{ displayText }</span>
        )
    }

    renderMenu(displayText) {
        return (
            <Dropdown id={`dropdown-${Math.random() * 200000}`}
                bsSize={this.props.bsSize} onSelect={this.onToggleText}>
                <Dropdown.Toggle className="textToggler">
                    { this.renderTextSpan(displayText) }
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    { this.props.textArray.map((text, inx) => {
                        const active = (this.state.selectedInx === inx)
                        return (
                            <MenuItem eventKey={inx} key={inx} >
                                <span className={active ? 'bold' : 'text-normal'}>{text}</span>
                            </MenuItem>
                        )
                    })}
                </Dropdown.Menu>
            </Dropdown>
        )
    }
}

export class ResultElement extends Component {
    static propTypes = {
        definition: BackendResultPropType,
        onToggleResult: PropTypes.func,
        toggled: PropTypes.bool,
        displayToggleBtn: PropTypes.bool,
        onChooseText: PropTypes.func,
    }

    static defaultProps = {
        onToggleResult: Function.prototype,
        onChooseText: Function.prototype,
        toggled: false,
        displayToggleBtn: true,
        headLanguage: 'english',
        listLanguage: 'spanish'
    }

    constructor(props) {
        super(props)
        this.onChooseText = this.onChooseText.bind(this)
    }

    toggle = () => {
        if (this.props.onToggleResult) {
            this.props.onToggleResult(this.props.definition)
        }
    }

    onChooseText(index, language) {
        return function(itemInx, itemText) {
            if (!this.props.onChooseText) {
                return
            }

            this.props.onChooseText(index, language, itemInx, itemText)
        }.bind(this)
    }

    render() {
        const def = this.props.definition,
              toggled = this.props.toggled

        const strClassNumber = ('0' + this.props.definition.classNumber).slice(-2)
        return (
            <div className="definitionResult">
                <div>
                    <Label bsStyle="success" style={{display: "block"}} title={`Clase ${strClassNumber}`}>
                        <small>{ strClassNumber }</small>
                    </Label>
                    <Label bsStyle="primary" title={ SOURCES_MAP[def.source] }>
                        <small>{ SourceMap[def.source] }</small>
                    </Label>
                </div>

                <div className="definition">
                    <div className="semi-bold">
                        <DefinitionText
                            textArray={def[this.props.headLanguage]}
                            displayDropdown={this.props.displayDropdown}
                            onChooseText={this.onChooseText(this.props.resultIndex, this.props.headLanguage)}
                        />
                    </div>
                    <div>
                        <DefinitionText
                            textArray={def[this.props.listLanguage]}
                            displayDropdown={this.props.displayDropdown}
                            onChooseText={this.onChooseText(this.props.resultIndex, this.props.listLanguage)}
                        />
                    </div>
                </div>

                {this.props.displayToggleBtn && (
                    <div>
                        <DefinitionToggleBtn
                            toggled={toggled}
                            minusIconOnNormal={this.props.minusIconOnNormal}
                            onClick={this.toggle}
                        />
                    </div>
                )}
            </div>
        )
    }
}

export class ResultsList extends Component {
    static defaultProps = {
        results: [],
        onToggleResult: Function.prototype,
        selectedIds: []
    }

    static propTypes = {
        results: PropTypes.arrayOf(BackendResultPropType),
        onToggleResult: PropTypes.func,
        selectedIds: PropTypes.arrayOf(PropTypes.string),
        headLanguage: PropTypes.string,
        listLanguage: PropTypes.string,
    }

    render() {
        return (
            <Panel.Collapse>
                {this.props.children && (
                    <Panel.Body className="result-group-header">
                        <span>{ this.props.children }</span>
                    </Panel.Body>
                )}
                <ListGroup>
                    {
                        this.props.results.map((definition, inx) => {
                            const toggled = !!~this.props.selectedIds.indexOf(
                                definition.id
                            )

                            return (
                                <ListGroupItem key={inx}
                                    definitionId={definition.id}
                                    style={{backgroundColor: toggled ? '#FFF59D' : ''}}
                                    className="animatedBackgroundColor"
                                >
                                    <ResultElement
                                        definition={definition}
                                        toggled={toggled}
                                        onToggleResult={this.props.onToggleResult}
                                        headLanguage={this.props.headLanguage}
                                        listLanguage={this.props.listLanguage}
                                        displayDropdown={this.props.displayDropdown}
                                    />
                                </ListGroupItem>
                            )
                        })
                    }
                </ListGroup>
            </Panel.Collapse>
        )
    }
}

export default class DefinitionSearchResult extends Component {
    static defaultProps = {
        searchResult: {
            query: 'Unknown query',
            docs: [],
            suggestions: [],
            numFound: 0,
            start: 0
        },
        onToggleResult: Function.prototype,
        selectedIds: [],
        headLanguage: 'english',
        listLanguage: 'spanish',
    }

    static propTypes = {
        searchResult: PropTypes.shape({
            query: PropTypes.string,
            docs: PropTypes.arrayOf(BackendResultPropType),
            suggestions: PropTypes.array,
            numFound: PropTypes.number,
            start: PropTypes.number,
        }),
        onToggleResult: PropTypes.func,
        headLanguage: PropTypes.string,
        listLanguage: PropTypes.string,
        selectedIds: PropTypes.arrayOf(PropTypes.string),
    }

    render() {
        const sr = this.props.searchResult,
              hasResults = !!sr.numFound,
              hasSuggestions = !!sr.suggestions.length
        return (
            <Panel defaultExpanded>
                <Panel.Heading>
                    <Panel.Title toggle>
                        <ResultHeader>{sr.query}</ResultHeader>
                    </Panel.Title>
                </Panel.Heading>
                <ResultsList
                    results={sr.docs}
                    onToggleResult={this.props.onToggleResult}
                    selectedIds={this.props.selectedIds}
                    headLanguage={this.props.headLanguage}
                    listLanguage={this.props.listLanguage}
                    displayDropdown={false}
                >
                    {!hasResults && <div className="text-danger">
                        No hay resultados para esta búsqueda
                    </div>}
                </ResultsList>

                {/* Suggestions */}
                {hasSuggestions && (
                    <ResultsList results={sr.suggestions}
                        onToggleResult={this.props.onToggleResult}
                        selectedIds={this.props.selectedIds}
                        headLanguage={this.props.headLanguage}
                        listLanguage={this.props.listLanguage}
                        displayDropdown={false}
                    >
                        <span id="suggestions-header">
                            Se encontraron {`${sr.suggestions.length}`} sugerencias para la búsqueda
                        </span>
                    </ResultsList>
                )}
            </Panel>
        )
    }
}

