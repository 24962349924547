import { notify } from 'react-notify-toast'
import copy from 'copy-html-to-clipboard'
import { makeHTMLTableRow } from '../utilities'

const defaultState = {
    definitionIds: [],
    definitions: []
}

export default {
    state: defaultState,
    reducers: {
        toggleDefinition(state, definition) {
            let newIds = state.definitionIds.slice(),
                newDefs = state.definitions.slice()
            const defId = definition.id,
                  foundInx = newIds.indexOf(defId)

            if (foundInx < 0) {
                newIds.push(definition.id)
                newDefs.push(Object.assign({}, definition))
            } else {
                newIds.splice(foundInx, 1)
                newDefs = newDefs.filter(def => def.id !== defId)
            }

            return {
                ...state,
                definitions: newDefs,
                definitionIds: newIds
            }
        },

        setChosenText(state, {index, language, label}) {
            let definition = Object.assign({}, state.definitions[index])
            definition[language]._kept_ = label
            state.definitions[index] = definition
            return state
        }
    },
    effects: {
        CopyToClipboard(asTable, {indexSearchResults, heldDefinitions}) {
            const col1Lng = indexSearchResults.searchParams.language,
                  col2Lng = col1Lng === 'spanish' ? 'english' : 'spanish'
            if (asTable) {
                const htmlRows = heldDefinitions.definitions.map(def => {
                    const classNumber = def.classNumber
                    const col1Text = def[col1Lng]._kept_ || def[col1Lng][0],
                          col2Text = def[col2Lng]._kept_ || def[col2Lng][0]

                    return makeHTMLTableRow(classNumber, col1Text, col2Text)
                })

                copy(`<table width="100%">${htmlRows.join('\n')}</table>`, {asHtml: true})
            }
            else {
                const definitions = heldDefinitions.definitions.map(def => {
                    return (def[col2Lng]._kept_ || def[col2Lng][0])
                })

                copy(definitions.join('; '), {asHtml: false})
            }

            notify.show('Las indicaciones seleccionadas se han copiado al portapapeles', 'success')
        }
    },
}
