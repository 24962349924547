import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {Grid, Row, Col, MenuItem } from 'react-bootstrap';
import SplitButton from 'react-bootstrap/lib/SplitButton'
import SearchForm from './forms';
import IndexResults from './results'
import HeldDefinitions from './heldDefinitions'
import { IndexSearchBlankState } from './../common/notices'


class IndexSearch extends Component {
    static defaultProps = {
        definitionsCount: 0,
        pristine: true,
    }

    static propTypes = {
        definitionsCount: PropTypes.number,
        pristine: PropTypes.bool,
    }

    sendTranslationsToClipboard = (asTable) => {
        this.props.onCopyToClipboard(!!asTable)
    }

    render() {
        return (
            <Grid fluid={true} className="applicationTab indexTab">
                <Row className="form">
                    <Col xs={12}>
                        <SearchForm />
                    </Col>
                </Row>

                { this.props.pristine &&
                    <Row>
                        <Col sm={12}>
                            <IndexSearchBlankState />
                        </Col>
                    </Row>
                }

                { !this.props.pristine &&
                    <Row style={{marginTop:"16px"}} className="result">
                        <Col sm={6}>
                            <div style={{overflow: 'auto'}}>
                                <h4 style={{fontWeight: 'bold', color: '#ff33a0'}}>Resultados de la búsqueda</h4>
                            </div>
                            <IndexResults />
                        </Col>

                        <Col sm={6}>
                            <div>
                                <h4 style={{fontWeight: 'bold', color: '#ff33a0'}}>Compilación</h4>
                            </div>

                            <HeldDefinitions/>

                            { this.props.definitionsCount > 0 &&
                                <div>
                                    <SplitButton
                                        bsStyle="success"
                                        id="SendToClipboardOptions"
                                        disabled={!this.props.definitionsCount}
                                        onSelect={this.sendTranslationsToClipboard}
                                        onClick={this.sendTranslationsToClipboard}
                                        title="Copiar Traducciones"
                                        bsSize="sm"
                                    >
                                        <MenuItem eventKey="">
                                            <span>Copiar traducciones como párrafo</span>
                                        </MenuItem>
                                    </SplitButton>
                                </div>
                            }
                        </Col>
                    </Row>
                }
            </Grid>
        )
    }
}

const stateMapper = state => ({
    definitionsCount: state.heldDefinitions.definitions.length,
    pristine: state.indexSearchResults.pristine,
})

const dispatchMapper = ({heldDefinitions}) => ({
    onCopyToClipboard: (asTable) => {
        heldDefinitions.CopyToClipboard(asTable)
    },
})

export default connect(stateMapper, dispatchMapper)(IndexSearch)
