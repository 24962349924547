import React, {Component} from 'react'
import PropTypes from 'prop-types'
import { Dropdown, MenuItem } from 'react-bootstrap'
import { ClassNumberToggler } from './index'

const displayLanguage = {
    english: "Inglés",
    spanish: "Español",
    french: "Francés",
}

export const OMPI_DEFS_SOURCES = {
    'niza11': 'Niza 11ra Edición',
    'niza10': 'Niza 10ma Edición',
    'niza9': 'Niza 9na Edición',
}

export const OFFICIAL_DEFS_SOURCES = Object.assign({}, OMPI_DEFS_SOURCES, {
    // 'IMPI': 'IMPI',
})

export const DEFAULT_DEFS_SOURCES = Object.assign({}, OFFICIAL_DEFS_SOURCES, {
    'WORLD': 'ClassSmart'
})

export class LanguageDropdown extends Component {
    static propTypes = {
        language: PropTypes.string,
        label: PropTypes.string,
        bsSize: PropTypes.string,
        onChange: PropTypes.func
    }

    constructor(props, context) {
        super(props, context)
        this.state = {language: props.language}
        this.onSelectOption = this.onSelectOption.bind(this)
    }

    onSelectOption(event) {
        this.setState({language: event})
        if (this.props.onChange) {
            this.props.onChange(event)
        }
    }

    render() {
        return (
            <Dropdown id="LanguageDropdown" onSelect={this.onSelectOption} bsSize={this.props.bsSize}>
                <Dropdown.Toggle>
                    {this.props.label ? <span className="text-muted" style={{paddingRight: '.35em'}}>{this.props.label}</span> : '' }
                    {displayLanguage[this.state.language] || 'Idioma'}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    { this.props.header &&
                        <MenuItem header={true} className="text-center">
                            <span>{this.props.header}</span>
                        </MenuItem>
                    }
                    <MenuItem id="english" eventKey={"english"}>
                        <span style={{fontWeight: (!this.state.language || this.state.language==='english') ? 'bold' : 'normal'}}>Inglés</span>
                    </MenuItem>
                    <MenuItem id="spanish" eventKey={"spanish"}>
                        <span style={{fontWeight: this.state.language==='spanish' ? 'bold' : 'normal'}}>Español</span>
                    </MenuItem>
                    <MenuItem id="french" eventKey={"french"}>
                        <span style={{fontWeight: this.state.language==='french' ? 'bold' : 'normal'}}>Francés</span>
                    </MenuItem>
                </Dropdown.Menu>
            </Dropdown>
        )
    }
}

export class ClassesDropdown extends Component {
    static defaultProps = {
        label: 'Clases',
        bsSize: 'small',
        classes: [],
        selectedClass: 0,
        singleClass: true,
        pullRight: false,
    }

    constructor(props) {
        super(props)
        this.updateClasses = this.updateClasses.bind(this)
        this.clearClasses = this.clearClasses.bind(this)
        this.arrayMode = (!this.props.singleClass)

        this.state = {
            classes: Array.isArray(props.classes) ? props.classes.slice() : [],
            selectedClass: props.selectedClass
        }
    }

    updateClasses(classes) {
        // Switch the presence of received class from the list of classes
        if (this.arrayMode) {
            this.setState({classes: classes})
        } else {
            this.setState({selectedClass: classes})
        }

        if (this.props.onChange)
            this.props.onChange(classes)
    }

    clearClasses() {
        this.setState({classes: [], selectedClass: undefined})
        this.updateClasses(this.arrayMode ? [] : undefined)
    }

    displayValue() {
        if (!this.arrayMode) {
            let selectedClass = undefined
            if (this.state.selectedClass) {
                selectedClass = ('0' + this.state.selectedClass).slice(-2)
            }

            const labelClassName = selectedClass ? 'text-success' : 'text-danger'
            selectedClass = selectedClass || 'Todas las Clases'
            return (
                <strong id="selected-value" className={labelClassName}>
                    { selectedClass }
                </strong>
            )
        }

        if (this.state.classes.length) {
            if (this.state.classes.length === 45)
                return 'Todas'

            if (this.state.classes.length > 3)
                return 'Varias Clases'

            return this.state.classes.join('; ')
        }

        return (
            <strong className="text-danger">Ninguna Clase</strong>
        )
    }

    render() {
        return (
            <Dropdown id="classDropdown" bsSize={this.props.bsSize} pullRight={this.props.pullRight}>
                <Dropdown.Toggle>
                    {this.props.label ? <span className="text-muted" style={{paddingRight: '.35em'}}>{this.props.label}</span> : '' }
                    { this.displayValue() }
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    <MenuItem header>
                        Limitar por Clase
                    </MenuItem>

                    <ClassNumberToggler
                        style={{width: "420px", padding: "0px 15px"}}
                        btnRadio={10}
                        onToggleClass={this.updateClasses}
                        displayResetButton={true} />
                </Dropdown.Menu>
            </Dropdown>
        )
    }
}


export class DefinitionSourceDropdown extends Component {
    static defaultProps = {
        label: 'Fuentes',
        bsSize: 'small',
        sources: ['niza10', 'IMPI', 'WORLD'],
        availableSources: DEFAULT_DEFS_SOURCES,
        pullRight: false,
        multiValued: true,
    }

    constructor(props) {
        super(props)
        this.state = {sources: this.props.sources.slice()}
        this.switchSource = this.switchSource.bind(this)
    }

    switchSource(source) {
        // Switch the presence of received from the list of sources
        let sourceInxOnList = this.state.sources.indexOf(source),
            sourceOnList = !!~sourceInxOnList,
            sources = this.state.sources.slice()

        if  (sourceOnList)
            sources.splice(sourceInxOnList, 1)
        else {
            sources.push(source)
        }

        if (!this.props.multiValued) {
            sources = [source]
        }

        this.setState({sources: sources.slice()})

        if (this.props.onChange)
            this.props.onChange(sources.slice())
    }

    displayValue() {
        if (this.state.sources.length) {
            return this.state.sources.join('; ')
        }

        return (
            <strong className="text-danger">Ninguna Fuente</strong>
        )
    }

    render() {
        return (
            <Dropdown id="SourcesList" onSelect={this.switchSource} bsSize={this.props.bsSize} pullRight={this.props.pullRight}>
                <Dropdown.Toggle>
                    { this.props.label ? <span className="text-muted" style={{paddingRight: '.3em'}}>{this.props.label}</span> : '' }
                    { this.displayValue() }
                </Dropdown.Toggle>
                <Dropdown.Menu>
                { this.props.header &&
                    <MenuItem header={true} className="text-center">
                        <span>{this.props.header}</span>
                    </MenuItem>
                }
                {
                    Object.getOwnPropertyNames(this.props.availableSources).map((sourceId, i) => {
                        return (
                            <MenuItem eventKey={sourceId} key={i}>
                                <span style={{fontWeight: (!!~this.state.sources.indexOf(sourceId)) ? 'bold' : 'normal'}}>
                                    { this.props.availableSources[sourceId] }
                                </span>
                            </MenuItem>
                        )
                    })
                }
                </Dropdown.Menu>
            </Dropdown>
        )
    }
}


