import React, { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { ListGroup, ListGroupItem } from 'react-bootstrap';
import { ResultElement, BackendResultPropType } from './results/nodes'


class HeldDefinitions extends Component {
    static defaultProps = {
        definition: {},
        onToggleDefinition: Function.prototype,
    }

    static propTypes = {
        definition: BackendResultPropType,
        onToggleDefinition: PropTypes.func,
    }

    render() {
        const headLanguage = this.props.searchParams.language,
              listLanguage = headLanguage === 'spanish' ? 'english' : 'spanish'

        return (
            <ListGroup>
                { this.props.definitions.map((definition, inx) => (
                    <ListGroupItem key={definition.id}>
                        <ResultElement
                            definition={definition}
                            toggled={true}
                            minusIconOnNormal={true}
                            headLanguage={headLanguage}
                            listLanguage={listLanguage}
                            onToggleResult={this.props.onToggleDefinition}
                            displayDropdown={true}
                            resultIndex={inx}
                            onChooseText={this.props.onChooseText}
                        />
                    </ListGroupItem>
                ))}
            </ListGroup>
        )
    }
}

const stateMapper = state => ({
    definitions: state.heldDefinitions.definitions,
    definitionsId: state.heldDefinitions.definitionsId,
    searchParams: state.indexSearchResults.searchParams,
})

const dispatchMapper = ({heldDefinitions}) => ({
    onCopyToClipboard: (result) => {
        console.log('Copy to clipboard!')
    },
    onChooseText: (index, language, labelIndex, label) => {
        heldDefinitions.setChosenText({index, language, labelIndex, label})
    },
    onToggleDefinition: (definition) => {
        heldDefinitions.toggleDefinition(definition)
    }
})

export default connect(stateMapper, dispatchMapper)(HeldDefinitions)
