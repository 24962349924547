export const SOURCES_MAP = {
    'niza9': 'Niza 9na edición',
    'niza9-B': 'Niza 9na edición',
    'niza10': 'Niza 10ma edición',
    'niza10-B': 'Niza 10ma edición',
    'niza11': 'Niza 11ra edición',
    'niza11-B': 'Niza 11ra edición',
    'WORLD': 'ClassSmart',
    'WORLD-B': 'ClassSmart',
}
