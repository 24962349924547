import React from 'react'
import PropTypes from 'prop-types'
import { LanguageDropdown, DefinitionSourceDropdown, ClassesDropdown } from './../common/dropdowns';
import { TranslationOutput } from './output';
import { ButtonToolbar, ButtonGroup, Button, Grid, Row, Col, FormControl, MenuItem } from 'react-bootstrap';
import SplitButton from 'react-bootstrap/lib/SplitButton'
import { notify } from 'react-notify-toast'
import { InfiniteProgress } from './../common/progress'
import { RequestErrorNotice } from './../common/notices'
import { makeHTMLTableRow } from '../../utilities'
import copy from 'copy-html-to-clipboard'


class DefinitionsTranslation extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            indications: '',
            from: 'english',
            to: 'spanish',
            sources: ['niza11', 'niza10', 'WORLD', 'IMPI'],
            classFilter: undefined,
            translations: props.translations
        }

        this.state = Object.assign(this.state, props)

        this.onUpdateSources = this.onUpdateSources.bind(this)
        this.onFromLanguageChanged = this.onFromLanguageChanged.bind(this)
        this.onToLanguageChanged = this.onToLanguageChanged.bind(this)
        this.onUpdateClasses = this.onUpdateClasses.bind(this)
        this.onUpdateSourceIndications = this.onUpdateSourceIndications.bind(this)
        this.sendTranslationCommand = this.sendTranslationCommand.bind(this)
        this.onTranslationsUpdate = this.onTranslationsUpdate.bind(this)
        this.translationsToClipboard = this.translationsToClipboard.bind(this)
    }

    /**
     * Sends to backend a translation command with the options defined by the user.
     */
    sendTranslationCommand() {
        // We should validate the user has provided valid options to proceed
        if (!this.state.indications.trim()) {
            return notify.show('Debe introducir alguna indicación para traducir', 'error')
        }

        if (!this.state.sources.length) {
            return notify.show('Debe elegir al menos una fuente de traducción', 'error')
        }

        if (this.props.fetching) {
            return false
        }

        const translation = {
            indications: this.state.indications,
            from: this.state.from,
            to: this.state.to,
            sources: this.state.sources,
            classFilter: this.state.classFilter,
            fetching: this.props.fetching,
        }

        if (this.props.onDoTranslation) {
            this.props.onDoTranslation(translation)
        }
    }

    onTranslationsUpdate(translations) {
        this.setState({
            translations
        })
    }

    /**
     * Copies the selected translations to the clipboard
     */
    translationsToClipboard(asTable=true) {
        const translations = this.state.translations.map(
            ({translation, source, isOriginal, classNumber}) => {
                const highlighted = isOriginal || (translation.join('') === source)
                return [
                    translation ? translation.join(' / ') : source,
                    makeHTMLTableRow(classNumber, source, translation, highlighted)
                ]
            }
        )

        const plainText = translations.map(t => t[0]).join('; '),
              htmlText = `<table>${translations.map(t => t[1]).join('\n')}</table>`

        if (asTable) {
            copy(htmlText, {asHtml: true})
        } else {
            copy(plainText)
        }

        notify.show('Se ha copiado las traducciones al portapapeles', 'success')
    }

    onUpdateSourceIndications(event) {
        this.setState({indications: event.target.value})
    }

    onFromLanguageChanged(language) {
        this.setState({from: language})
    }

    onToLanguageChanged(language) {
        this.setState({to: language})
    }

    onUpdateSources(sources) {
        this.setState({'sources': sources})
    }

    onUpdateClasses(selectedClass) {
        this.setState({'classFilter': selectedClass})
    }

    sendTranslationsToClipboard = (asTable) => {
        this.translationsToClipboard(!!asTable)
    }

    render() {
        const translationsLength = (this.props.translations || []).length,
              translating = this.props.fetching,
              fetchError = this.props.error

        return (
            <Grid fluid={true} className="translationInterface">
                <Row>
                    <Col xs={12}>
                        <h4 style={{fontWeight: 'bold', color: '#ff33a0'}}>Traducir Especificaciones</h4>
                    </Col>
                </Row>

                <Row className="toolbar">
                    <Col xs={12}>
                        <ButtonToolbar>
                            <ButtonGroup bsSize="small">
                                <LanguageDropdown
                                    language={this.state.from}
                                    onChange={this.onFromLanguageChanged}
                                    label="Desde"
                                    bsSize="small"
                                    header="Idioma de Origen"
                                />
                                <LanguageDropdown
                                    language={this.state.to}
                                    onChange={this.onToLanguageChanged}
                                    label="Hacia"
                                    bsSize="small"
                                    header="Idioma de Destino"
                                />
                            </ButtonGroup>

                            <ButtonGroup bsSize="small">
                                <DefinitionSourceDropdown
                                    bsSize="small"
                                    sources={this.state.sources}
                                    onChange={this.onUpdateSources}
                                    header="Fuentes para Traducción"
                                />
                                <ClassesDropdown
                                    bsSize="small"
                                    selectedClass={this.state.classFilter}
                                    onChange={this.onUpdateClasses}
                                />
                            </ButtonGroup>

                            <ButtonGroup className="pull-right">
                                <Button bsStyle="success" bsSize="small" onClick={this.sendTranslationCommand}>
                                    Traducir
                                </Button>
                            </ButtonGroup>
                        </ButtonToolbar>
                    </Col>
                </Row>

                <Row className="translation">
                    <Col xs={12}>
                        <FormControl
                            componentClass="textarea"
                            rows="6"
                            style={{resize: 'vertical'}}
                            placeholder="Indicaciones a traducir separadas por ';'"
                            onChange={this.onUpdateSourceIndications}
                            value={this.state.indications}
                        />
                    </Col>
                </Row>

                { fetchError &&
                    <Row>
                        <Col xs={12}>
                            <RequestErrorNotice error={this.props.error} />
                        </Col>
                    </Row>
                }

                { translationsLength > 0 &&
                    <Row>
                        <Col xs={12}>
                            <h4 style={{fontWeight: 'bold', color: '#ff33a0'}}>
                                Traducción de { this.props.translations.length } especificaciones
                            </h4>
                        </Col>
                    </Row>
                }

                { translating &&
                    <Row>
                        <Col xs={12}>
                            <InfiniteProgress />
                        </Col>
                    </Row>
                }

                { (translationsLength > 0 && !translating) &&
                    <Row className="translation">
                        <Col xs={12}>
                            <TranslationOutput
                                key={this.props.requestId}
                                translations={this.props.translations}
                                displayLanguage={this.state.to}
                                onTranslationsUpdate={this.onTranslationsUpdate} />
                        </Col>
                    </Row>
                }
                { (translationsLength > 0 && !translating) &&
                    <Row className="toolbar">
                        <Col xs={12}>
                                <SplitButton
                                    bsStyle="success"
                                    id="SendToClipboardOptions"
                                    onSelect={this.sendTranslationsToClipboard}
                                    onClick={this.sendTranslationsToClipboard}
                                    title="Copiar Traducciones"
                                    bsSize="sm"
                                >
                                    <MenuItem eventKey="">
                                        <span>Copiar traducciones como párrafo</span>
                                    </MenuItem>
                                </SplitButton>
                        </Col>
                    </Row>
                }
            </Grid>
        )
    }
}

DefinitionsTranslation.defaultProps = {
    indications: '',
    translations: [],
    classFilter: 0,
    from: 'english',
    to: 'spanish',
    fetching: false,
    sources: ['IMPI10', 'WORLD']
}

DefinitionsTranslation.propTypes = {
    classFilter: PropTypes.number,
    indications: PropTypes.string,
    translations: PropTypes.array,
    sources: PropTypes.array,
    from: PropTypes.string,
    to: PropTypes.string,
    fetching: PropTypes.bool,
    onDoTranslation: PropTypes.func,
}

export default DefinitionsTranslation;
