import React, { Component } from 'react'
import { Grid, Row, Col } from 'react-bootstrap'
import { ConnectedClassHeadingSearchInterface } from './elements'


class ClassHeadingSearch extends Component {
    render() {
        return (
            <Grid fluid={true} className="applicationTab indexTab">
                <Row>
                    <Col xs={12}>
                        <ConnectedClassHeadingSearchInterface />
                    </Col>
                </Row>
            </Grid>
        )
    }
}

export default ClassHeadingSearch
