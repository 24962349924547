import React, {Component} from 'react'
import PropTypes from 'prop-types'
import DefinitionElement from './definitionElement'

/**
 * Handles the display of an automatic translation result.
 *
 * **Required props**:
 *  - translations: An array of translations to display
 *  - displayLanguage: The language to use for translation
 *  - onTranslationsUpdate: Function called when a user chooses a translation
 */
export class TranslationOutput extends Component {
    static propTypes = {
        translations: PropTypes.array,
        displayLanguage: PropTypes.string,
        onTranslationsUpdate: PropTypes.func
    }

    constructor(props) {
        super(props)
        this.state = {
            translations: this.props.translations.slice()
        }

        this.onChangeTranslation = this.onChangeTranslation.bind(this)
    }

    onChangeTranslation(translationIndex, translation, isOriginal) {
        this.setState((prevState, props) => {
            let translations = prevState.translations.slice()
            translations[translationIndex] = Object.assign({}, translation, {isOriginal})

            if (this.props.onTranslationsUpdate) {
                this.props.onTranslationsUpdate(translations)
            }

            return {
                translations
            }
        })
    }

    render() {
        return (
            <TranslationTableOutput
                translations={this.state.translations}
                onChangeTranslation={this.onChangeTranslation}
                language={this.props.displayLanguage}
            />
        )
    }
}


/**
 * Displays a table with the search results. The table displays the original
 * definition on first column and the the found translation on the second.
 *
 * **Required props**:
 *  - translations: An array of translations to display
 *  - displayLanguage: The language to use for translation
 *  - onTranslationsUpdate: Function called when a user chooses a translation
 */
class TranslationTableOutput extends Component {
    render() {
        return (
            <table className="definitionTranslationTable"
                   id="applicationTranslationTableInterface">
                <tbody>
                    <tr>
                        <th width="50%">Original</th>
                        <th>Traducción</th>
                    </tr>
                    {
                        this.props.translations.map((translation, i) =>
                            <DefinitionElement
                                key={i}
                                translationIndex={i}
                                view="trtd"
                                onChangeTranslation={this.props.onChangeTranslation}
                                source={translation.source}
                                literal={translation.literal}
                                language={this.props.language}
                                translations={translation.translations}/>
                        )
                    }
                </tbody>
            </table>
        )
    }
}
