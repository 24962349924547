import { connect } from 'react-redux'
import SearchForm from './components'


const dispatchMapper = ({indexSearchForm: {executeSearch}}) => ({
    submitIndexSearch: (searchSetup) => {
        executeSearch(searchSetup)
    }
})

export default connect(state => state, dispatchMapper)(SearchForm)
