import fetch from 'isomorphic-fetch';
import { notify } from 'react-notify-toast'

const defaultState = {
    invalidated: true,
    fetching: false,
    searchParams: {},
    withError: false,
    error: null,
    results: [],
    pristine: true,
}

export default {
    state: defaultState,
    reducers: {
        setSearchParams(state, searchParams) {
            return { ...state, searchParams }
        },
        setInvalidated(state, invalidated) {
            return { ...state, invalidated }
        },
        setFetching(state, fetching) {
            return { ...state, fetching }
        },
        setError(state, error) {
            return { ...state, withError: true, error}
        },
        setResults(state, results) {
            return { ...state, results }
        },
        setPristine(state, pristine=true) {
            return { ...state, pristine }
        },
    },
    effects: (dispatch) => ({
        async performIndexSearch(searchParams) {
            const canPerformSearch = await this.canSendIndexSearchRequest(searchParams)

            if (!canPerformSearch) {
                return false
            }

            // evaluate some conditions before proceeding
            if (!searchParams.sources.length) {
                return notify.show(
                    'Debe usar alguna fuente para la búsqueda', 'error')
            }

            dispatch.indexSearchResults.fetchResults(searchParams).then(() => {
                this.setSearchParams(searchParams)
            })
        },

        canSendIndexSearchRequest(searchParams, {indexSearchResults}) {
            const state = indexSearchResults
            if (state.fetching || !searchParams.query) {
                return false
            }

            const someSettingChanged = Object.getOwnPropertyNames(searchParams).some(setting => {
                return String(searchParams[setting]) !== String(state.searchParams[setting])
            })

            return someSettingChanged || state.invalidated
        },

        fetchResults({query, ...params}) {
            this.setInvalidated(true)
            this.setFetching(true)
            this.setPristine(false)

            const currentHref = window.location.href
            const url = new URL(`/api/search/${query.trim()}`, currentHref)
            url.searchParams.append('language', params.language || 'english')
            params.sources.forEach(source => {
                url.searchParams.append('sources', source)
            })
            if (params.classNumber) {
                url.searchParams.append('class', params.classNumber)
            }

            return fetch(url)
                .then(r => r.json())
                .then(results => {
                    if (!Array.isArray(results)) {
                        throw Error("An array of documents wasn't returned")
                    }

                    this.setResults(results)
                })
                .catch(error => {
                    // handle when result.response.docs array is not returned.
                    console.log(error)
                    this.setError(error)
                    // dispatch(backendErrorWithResults(
                    //     'El servicio no retornó los resultados esperados.'
                    // ))
                })
                .catch(error => {
                    // Handle when backend doesnt return a valid JSON string.
                    console.error(error)
                    this.setError(error)
                    // dispatch(backendErrorWithResults(
                    //     'El servicio no retornó una cadena JSON válida.'))
                })
                .finally(response => {
                    this.setFetching(false)
                    this.setInvalidated(false)
                })
        }
    })
}
