/**
 * Returns a copy of a string with its first char in upper case.
 * If the length of the string received is lower than two chars,
 * then the string is returned without changes, without its first
 * char capitalized.
 *
 * @param {String} text String to capitalize its first char
 * @return {String}
 */
export function firstCharToUpperCase(text) {
    if (text.length > 1) {
        return `${text[0].toUpperCase()}${text.substr(1)}`
    }

    return text
}

export function makeHTMLTableRow(clNumber, col1, col2, highlightOutput) {
    const finalOutput = highlightOutput ? `<font color="red">${col2}</font>` : col2
    const classNumber = ('0' + clNumber).slice(-2)
    return `<tr><td>${classNumber}</td><td width="50%">${col1}</td><td>${finalOutput}</td></tr>`
}

export function makeHTMLTextSpan(text, highlighted) {
    const finalOutput = highlighted ? `<font color="red">${text}</font>` : text
    return `<span>${finalOutput}</span>`
}
