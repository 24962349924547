import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Grid, Row, Col } from 'react-bootstrap'
import {
    Form,
    InputGroup,
    FormControl } from 'react-bootstrap';
import { ClassNumberToggler } from './../common'
import {
    LanguageDropdown, DefinitionSourceDropdown
} from './../common/dropdowns'
import { ClassHeadingSearchBlankStat } from './../common/notices'

const AVAILABLE_HEADING_SOURCES = {
    'niza11': 'Niza 11ra Edición',
    'niza10': 'Niza 10ma Edición',
}

export const ClassHeadingElement = (
        {headingParts: {classNumber, HEADING, EXPLANATION, INCLUDES, NOT_INCLUDES}}) => {
    const explanation = EXPLANATION ? EXPLANATION.join('') : '',
          heading = HEADING ? HEADING.join('') : '',
          includes = INCLUDES ? INCLUDES.slice() : [],
          notIncludes = NOT_INCLUDES ? NOT_INCLUDES.slice() : []

    return (
        <div>
            <h4 className="interface-header">{`Clase ${("0" + classNumber).slice(-2)}`}</h4>
            {(heading && <h5>{heading}</h5>)}
            {(explanation && <p>{explanation}</p>)}

            { includes.length ? (
                <div>
                    <h5>Esta Clase Incluye</h5>
                    <ul>
                        {
                            includes.map((included, i) => (
                                <li key={i}>{included}</li>
                                ))
                            }
                    </ul>
                </div>
            ) : undefined }

            {notIncludes.length ? (
                <div>
                    <h5>Esta Clase No Incluye</h5>
                    <ul>
                        {
                            notIncludes.map((included, i) => (
                                <li key={i}>{included}</li>
                            ))
                        }
                    </ul>
                </div>
            ) : undefined}
        </div>
    )
}

class ClassHeadingSearchForm extends Component {
    static propTypes = {
        onToggleClass: PropTypes.func,
        onToggleLanguage: PropTypes.func,
        onToggleSource: PropTypes.func,
        onSubmitSearchForm: PropTypes.func
    }

    constructor(props) {
        super(props)
        this.state = {
            query: props.query
        }
    }

    onChangeInputValue = (event) => {
        this.setState({query: event.target.value})
    }

    submitSearch = (event) => {
        event.preventDefault()
        if (this.props.onSubmitSearchForm) {
            this.props.onSubmitSearchForm(this.state.query)
        }
    }

    render() {
        return (
            <Form horizontal={true} onSubmit={this.submitSearch}>
                <h4 className="interface-header">
                    Buscar en encabezados
                </h4>

                <InputGroup size="sm">
                    <InputGroup.Button>
                        <LanguageDropdown
                            language={"english"}
                            onChange={this.props.onToggleLanguage}
                            label=""
                            bsSize="small"
                            header="Idioma del Encabezado"
                        />
                    </InputGroup.Button>

                    <InputGroup bsSize={"sm"} style={{width: "100%"}}>
                        <FormControl
                            placeholder="Buscar..."
                            onChange={this.onChangeInputValue}
                            style={{borderRadius: '0px', borderLeft: 'none'}} />
                    </InputGroup>

                    <InputGroup.Button>
                        <DefinitionSourceDropdown
                            bsSize="small"
                            pullRigth={true}
                            label=""
                            sources={["niza10"]}
                            onChange={this.props.onToggleSource}
                            availableSources={AVAILABLE_HEADING_SOURCES}
                            multiValued={false}
                            header="Mostrar Encabezados" />
                    </InputGroup.Button>
                </InputGroup>

                <h4 className="interface-header as-visual-separator">
                    Ver encabezado de una clase
                </h4>

                <ClassNumberToggler onToggleClass={this.props.onToggleClass} />
            </Form>
        )
    }
}

export class ClassHeadingSearchInterface extends Component {
    static propTypes = {
        onToggleClass: PropTypes.func,
        onToggleLanguage: PropTypes.func,
        onToggleSource: PropTypes.func,
        onSubmitSearchForm: PropTypes.func
    }

    render() {
        return (
            <Grid fluid={true}>
                <Row>
                    <Col lg={3} sm={4}>
                        <ClassHeadingSearchForm
                            onToggleClass={this.props.onToggleClass}
                            onToggleLanguage={this.props.onToggleLanguage}
                            onToggleSource={this.props.onToggleSource}
                            onSubmitSearchForm={this.props.onSubmitSearchForm}
                        />
                    </Col>

                    <Col lg={7} sm={8}>
                        {(this.props.pristine && <ClassHeadingSearchBlankStat />)}
                        {
                            this.props.results.map((result) => (
                                <ClassHeadingElement
                                    headingParts={result}
                                    key={result.classNumber} />
                            ))
                        }
                    </Col>
                </Row>
            </Grid>
        )
    }
}

// Redux connected components
const PropsMapperForClassHeadingSearchInterface = ({headings}) => ({
    pristine: headings.pristine,
    somethingFound: headings.somethingFound,
    results: headings.results,
})

const EventMapperForClassHeadingSearchInterface = ({headings}) => ({
    onToggleLanguage: (language) => {
        headings.setFormState({language})
        headings.searchHeadings()
    },

    onToggleSource: (source) => {
        headings.setFormState({source})
        headings.searchHeadings()
    },

    onSubmitSearchForm: (query) => {
        headings.setQuery(query.trim())
        headings.searchHeadings()
    },

    onToggleClass: (classNumber) => {
        headings.setFormState({classNumber})
        headings.setQuery(String(classNumber))
        headings.searchHeadings()
    }
})

export const ConnectedClassHeadingSearchInterface = connect(
    PropsMapperForClassHeadingSearchInterface,
    EventMapperForClassHeadingSearchInterface
)(ClassHeadingSearchInterface)
