import React, {Component} from 'react'
import PropTypes from 'prop-types'
import { Label, Dropdown, MenuItem } from 'react-bootstrap';
import { SOURCES_MAP } from './../../constants';
import { firstCharToUpperCase } from './../../utilities';


const DEF_HIGHLIGHTED_BK_COLOR = 'hsl(60, 100%, 90%)'

/**
 * Interactive menu to choose a translation from a list.
 */
class TranslationPopupMenu extends Component {
    constructor(props) {
        super(props)
        this.onSelectTranslation = this.onSelectTranslation.bind(this)
        this.getSelectedTranslation = this.getSelectedTranslation.bind(this)
        this.elemText = this.elemText.bind(this)
        this.buildOptionsMenu = this.buildOptionsMenu.bind(this)
        this.doOptionsGroup = this.doOptionsGroup.bind(this)
        this.doMenuTranslationOption = this.doMenuTranslationOption.bind(this)
        this.onDisplayOptionsMenu = this.onDisplayOptionsMenu.bind(this)

        if (props.literal) {
            return
        }

        const firstTranslation = {...props.translations[0]},
              translationVariantsLength = (firstTranslation[this.props.language] || []).length

        firstTranslation[this.props.language] =
            [...firstTranslation[this.props.language] || []].slice(0, 1)
        this.state = {
            sourceUsedAsTranslation: false,
            translation: firstTranslation,
            translationVariantsLength,
            groupedTranslations: this.groupBySource(props.translations, props.language),
            translationsLength: props.translations.length
        }
    }

    componentDidMount() {
        // When constructing this element, we select the first translation by default.
        // After component construction we notifies component about the selected translation.
        if (this.props.onChangeTranslation) {
            this.props.onChangeTranslation(
                this.props.translationIndex,
                this.getSelectedTranslation(this.state.translation,
                !this.props.translations.length
            ))
        }

        this.setState({
            menuPositionCalculated: false
        })
    }

    groupBySource(translations, language) {
        return translations.reduce((groups, translation) => {
            const groupName = SOURCES_MAP[translation.source]
            const array = groups[groupName] || []

            // A translation may include more than one version of
            // a definition.  So we extract into individual translations
            // this each variation inside the definition.
            groups[groupName] = array.concat(
                expandDefinitionVariations(translation, language))
            return groups
        }, {})

        function expandDefinitionVariations(translation, propName) {
            const defVariations = translation[propName]
            return defVariations.map(text => {
                const subTranslation = Object.assign({}, translation)
                subTranslation[propName] = [text]
                return subTranslation
            })
        }
    }

    elemText() {
        if (this.state.translation[this.props.language].length) {
            const classNumber = ("0" + this.state.translation.classNumber).slice(-2)
            let elemText = this.state.translation[this.props.language].map(firstCharToUpperCase).join(' / ')

            if (this.state.translation[this.props.language].length > 1) {
                // When the current selected translation has more than
                // one variation, we highlight the text element
                elemText = (
                    <span style={{backgroundColor: DEF_HIGHLIGHTED_BK_COLOR}}>
                        {elemText}
                    </span>
                )
            }

            return (
                <span>
                    <Label bsStyle="success" style={{marginRight: "3px"}} title={`Clase ${classNumber}`}>
                        <small>{ classNumber }</small>
                    </Label>
                    <span>{ elemText }</span>
                </span>
            )
        }

        return (
            <span className={ this.state.sourceUsedAsTranslation ? 'text-danger' : ''}>
                { this.props.source }
            </span>
        )
    }

    onSelectTranslation(translation) {
        let sourceUsedAsTranslation  = (
            translation === this.props.source
        )

        this.setState({translation, sourceUsedAsTranslation})
        if (this.props.onChangeTranslation) {
            this.props.onChangeTranslation(
                this.props.translationIndex,
                this.getSelectedTranslation(translation),
                sourceUsedAsTranslation
            )
        }
    }


    /**
     * Returns an object mapping the definition source with the received translation
     * @param {Translation} translation A translation object
     *
     * @returns {Object} translation - A translation object
     * @returns {Number} translation.classNumber - Class number of translation
     * @returns {string} translation.source - Source of the translation
     * @returns {Array} translation.translation - Translation in active language
     */
    getSelectedTranslation(translation) {
        let dt = translation[this.props.language],
            translationText = undefined

        translationText = dt.length ? dt.map(firstCharToUpperCase) : [this.props.source]

        return {
            classNumber: translation.classNumber,
            source: this.props.source,
            translation: translationText
        }
    }

    buildOptionsMenu(translations) {
        const items = []
        items.push(
            <MenuItem header={false} key="source" eventKey={this.props.source}>
                <div>
                    <small className="text-muted">Original</small>
                </div>
                <div>
                    <strong className="text-danger">{ this.props.source }</strong>
                </div>
            </MenuItem>
        )
        Object.keys(translations).forEach((groupName, inx) => {
            this.doOptionsGroup(
                groupName,
                translations[groupName].filter(t => t[this.props.language]),
                items,
                inx,
                (inx === Object.keys(translations).length -1))
        })

        return items
    }

    doOptionsGroup(groupName, options, menuItems, inx, isLastOne) {
        if (!options.length) {
            return
        }

        menuItems.push(
            <MenuItem header={true} key={`header.${inx}`} className="sourceHeader">
                <strong><small>{groupName}</small></strong>
            </MenuItem>
        )

        options.map(this.doMenuTranslationOption).forEach(menuItem => {
            menuItems.push(menuItem)
        })
    }

    doMenuTranslationOption(translation, inx) {
        return (
            <MenuItem key={translation.source + inx} eventKey={translation}>
                <span>{ translation[this.props.language] || translation.source}</span>
            </MenuItem>
        )
    }

    onDisplayOptionsMenu(isOpen, event, source) {
        // Calculate the horizontal position of the options menu.
        // Here we avoid that a right sided aligned menu falls outside
        // the left viewport border. If this happens, we simply align
        // the options menu to the left.
        if (!isOpen || this.state.menuPositionCalculated) {
            return
        }

        try {
            const btnTrigger = event.currentTarget,
                  menu = event.currentTarget.nextElementSibling,
                  parentTD = menu.closest('td'),
                  TDWidth = parseInt(window.getComputedStyle(parentTD, null).getPropertyValue('width'), 10),
                  TDLPad = parseInt(window.getComputedStyle(parentTD, null).getPropertyValue('padding-left'), 10),
                  TDRPad = parseInt(window.getComputedStyle(parentTD, null).getPropertyValue('padding-right'), 10)

            // Temporally display the menu to calculate its client position
            Object.assign(menu.style, {
                display: 'block',
                visibility: 'hidden',
                top: null,
                bottom: null,
                maxHeight: null,
                overflow: null,
                width: `${TDWidth - TDLPad - TDRPad}px`
            })

            const rect = menu.getBoundingClientRect(),
                  availHeight = document.body.clientHeight,
                  triggerRect = btnTrigger.getBoundingClientRect()  ,
                  shouldUpFold = (rect.y + rect.height) > availHeight,
                  upFoldWillOverflow = triggerRect.top < rect.height,
                  shouldScrollMenu = rect.height > (availHeight - 16)

            if (shouldUpFold && !upFoldWillOverflow) {
                Object.assign(menu.style, {
                    bottom: `${triggerRect.height + 3}px`,
                    top: 'inherit'
                })
            }

            if (shouldUpFold && upFoldWillOverflow) {
                Object.assign(menu.style, {
                    top: `${(rect.y - triggerRect.height - 8) * -1 }px`,
                    bottom: 'inherit'
                })
            }

            if (shouldScrollMenu) {
                // 16px is the sum of an 8px padding on top and bottom
                Object.assign(menu.style, {
                    maxHeight: `${availHeight - 16}px`,
                    overflow: 'auto'
                })

                menu.scrollTop = 0;
            }

            // Restore the CSS inline values to default
            Object.assign(menu.style, {
                display: '',
                visibility: ''
            })
        }
        finally {
        }
    }

    shouldComponentUpdate = (nextProps, nextState) => {
        // Do not update component when props changes
        // During an instance of this component life cycle, the props that
        // may change are the ones relative to search params (language, class)
        // The changes in the search translation params does not influence the
        // state of instance once it is created. An instance of this component
        // is created from a translation search result, an these results don't
        // change until a completely new search query is performed

        // Return true only when there is a change in state
        return JSON.stringify(this.state) !== JSON.stringify(nextState)
    }

    render() {
        if (this.props.literal) {
            return (
                <span className="translatedDefinitionSingle">{this.props.source}</span>
            )
        }

        // Display a drop down menu when a definition has more than one
        // translation from different sources or from the same source.
        const multipleTranslationsAvailable = (
            Math.max(this.state.translationsLength, this.state.translationVariantsLength) > 1
        )
        if (multipleTranslationsAvailable) {
            return (
                <Dropdown id="translation" onSelect={this.onSelectTranslation}
                            bsSize={this.props.bsSize || 'md'}
                            onToggle={this.onDisplayOptionsMenu}>
                    <Dropdown.Toggle className="translationDropdown">
                        <span style={{userSelect: 'text'}}>{ this.elemText() }</span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        { this.buildOptionsMenu(this.state.groupedTranslations) }
                    </Dropdown.Menu>
                </Dropdown>
            )
        }
        else {
            return (
                <span className="translatedDefinitionSingle" title={this.props.source}
                      style={{
                          'color': !this.state.translationsLength ? 'red' : 'inherit',
                          'backgroundColor': !this.state.translationsLength ? 'lightPink' : 'inherit'
                    }}>
                        {this.elemText()}
                </span>
            )
        }
    }
}

class TableRowTranslationElement extends TranslationPopupMenu {
    render() {
        return (
            <tr>
                <td>
                    <i>{this.props.source}</i>
                </td>
                <td>
                    <span className="translatedDefinition">
                        { super.render() }
                    </span>
                </td>
            </tr>
        )
    }
}

class SpanTranslationElement extends TranslationPopupMenu {
    render() {
        return (
            <div className="translatedDefinition">
                {super.render() }
                <span>;&nbsp;</span>
            </div>
        )
    }
}

/**
 * Component used to display the result of an automatic translation result.
 * This component is displayed as a dropdown menu with available translations
 * when its *view* mode is `span`. Otherwise the dropdown is displayed in a
 * table row along the original definition searched.
 *
 * **Required props**
 *  - translationIndex: Index of this translation when this is part of an array
 * of translations.
 *  - onChangeTranslation: Handler function that is called when a user selects
 * a translation option from the displayed dropdown.
 *  - source: Language of the source definition.
 *  - literal: (bool) `true` when the translation is literal
 * (can't be changed by the user).
 *  - language: Language to use for translations displayed in the dropdown.
 *  - view: View mode to use for displaying the dropdown. `span` when it should
 * be displayed as a inline dropdown. `trtd` When it should be displayed as a
 * table row along side the original definition. `span` is the default.
 */
export default class DefinitionElement extends Component {
    static propTypes = {
        translationIndex: PropTypes.number.isRequired,
        onChangeTranslation: PropTypes.func,
        source: PropTypes.string.isRequired,
        literal: PropTypes.bool,
        language: PropTypes.string.isRequired,
        view: PropTypes.oneOf(['span', 'trtd']),
    }

    static defaultProps = {
        view: 'span',
    }

    render() {
        const {view, ...props} = this.props

        switch (view) {
            case 'trtd':
                return <TableRowTranslationElement {...props} />

            default:
                return <SpanTranslationElement {...props} />
        }
    }
}
