export default {
    state: {
        pristine: true,
        fetching: false,
        somethingFound: false,
        results: [],
        form: {},
    },

    reducers: {
        setFetching(currentState, fetching) {
            return { ...currentState, fetching, pristine: false }
        },

        setQuery(currentState, query) {
            return { ...currentState, query }
        },

        setSearchResults(currentState, results) {
            const somethingFound = Boolean(results.length)
            return { ...currentState, results, somethingFound, fetching: false}
        },

        setFormState(currentState, payload) {
            const nextFormState = { ...currentState.form, ...payload }
            return { ...currentState, form: nextFormState }
        }
    },

    effects: (dispatch) => ({
        async searchHeadings(payload, {headings}) {
            const {fetching, form} = headings,
                  query = payload || headings.query

            if (fetching) {
                return false
            }

            if (!query) {
                return false
            }

            this.setFetching(true)
            const url = new URL(`/api/headings/${query}`, window.location.href)

            // Append any filter
            Object.getOwnPropertyNames(form).forEach(param => {
                url.searchParams.append(param, form[param])
            })

            const fetchResultsRequest = fetch(url).then(r => r.json())
            fetchResultsRequest.then(this.setSearchResults)

            fetchResultsRequest.catch(error => {
                this.setFetching(false)
            })

            return fetchResultsRequest
        }
    })
}
