

const defaultState = {
    params: {
        indications: '',
        from: 'english',
        to: 'spanish',
        sources: ['niza11'],
        classFilter: undefined,
    },
    lastParams: {},
    fetching: false,
    error: undefined,
    translations: [],
    requestId: undefined,
}

export default {
    state: defaultState,
    reducers: {
        setTranslationParams(state, params) {
            return {...state, params}
        },

        setTranslationStart(state) {
            return {
                ...state,
                fetching: true,
                lastParams: {...state.params},
                error: undefined
            }
        },

        setTranslationEnd(state) {
            return {...state, fetching: false}
        },

        setTranslationResults(state, translations) {
            return {
                ...state,
                translations,
                requestId: Math.random()
            }
        },

        setTranslationError(state, error) {
            return {...state, fetching: false, error, translations: []}
        }
    },
    effects: (model) => ({
        /**
         * Performs a translation request if the received translation
         * request settings are different from those in current application state.
         * @param {Object} translation Object with translation request settings
         */
        async performTranslation() {
            const {translations} = model
            if (!(await translations.canPerformTranslation())) {
                return false
            }

            translations.setTranslationStart()
            translations.sendTranslationRequest()
        },

        /**
         * Returns true if there is a change in translation settings between
         * current application state and a new application state.
         * @param {Object} state Current application state
         * @param {Object} translation New application state
         *
         * @return {boolean} true if there is a change between the two application states.
         */
        canPerformTranslation(payload, {translations}) {
            const {lastParams, params} = translations,
                  {indications} = params

            const anyChanged = Object.getOwnPropertyNames(params).some(prop => {
                return params[prop] !== lastParams[prop]
            })

            if (!indications.trim() || !anyChanged || translations.fetching) {
                return false
            }

            return true
        },

        /**
         * Sends a translation request to the backend notifying
         * the state store with the changes in the translation request
         * @param {Object} translation Translation request settings
         */
        sendTranslationRequest(_, {translations}) {
            const request = fetch('/api/translate', {
                headers: new Headers({'Content-Type': 'application/json'}),
                method: 'POST',
                body: JSON.stringify(translations.params)
            })

            request.then(response => {
                if (!response.ok) {
                    throw Error({status: response.status})
                }

                return response.json()
            })
            .then(results => {
                model.translations.evalTranslationRequestResult({results})
            })

            request.catch(err => {
                model.translations.evalTranslationRequestResult({err})
            })

            return request
        },

        evalTranslationRequestResult({err, results}) {
            const {translations} = model

            translations.setTranslationEnd()
            if (!err) {
                translations.setTranslationResults(results)
            } else {
                console.log(err)
                translations.setTranslationError(err)
            }
        }
    })
}
