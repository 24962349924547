import React, { Component } from 'react';
import IndicationsTranslator from './../containers/translation';
import {Grid, Row, Col, Tabs, Tab} from 'react-bootstrap';

import IndexSearch from './indexSearch'
import ClassHeadingSearch from './classHeadingSearch'
import Notifications from 'react-notify-toast'

class Application extends Component {
    render() {
        return (
            <Grid fluid={true} className="application">
                <Notifications />
                <Row style={{height: '100%'}}>
                    <Col xs={12} style={{height: '100%'}}>
                        <ApplicationInterface />
                    </Col>
                </Row>
            </Grid>
        )
    }
}


export class ApplicationInterface extends Component {
    render() {
        return (
            <Tabs defaultActiveKey={1} id="default-application-interface">
                <Tab eventKey={1} title="Traducción de Especificaciones">
                    <div className="applicationTab translationTab">
                        <IndicationsTranslator />
                    </div>
                </Tab>

                <Tab eventKey={2} title="Índice de Especificaciones">
                    <IndexSearch />
                </Tab>

                <Tab eventKey={3} title="Encabezados">
                    <ClassHeadingSearch />
                </Tab>
            </Tabs>
        )
    }
}

export default Application
