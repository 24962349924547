export default {
    state: {},
    reducers: {
        setSetup(state, payload) {
            // Update index search setup
            return { ...state, ...payload}
        }
    },
    effects: (dispatch) => ({
        executeSearch(payload) {
            const search = dispatch.indexSearchResults.performIndexSearch(payload)
            search.then(() => {
                dispatch.indexSearchForm.setSetup(payload)
            })
        },
    })
}
