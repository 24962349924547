import React, {Component} from 'react'
import PropTypes from 'prop-types'

import {
    Form,
    InputGroup,
    Button,
    FormControl } from 'react-bootstrap';

import {
    ClassesDropdown, LanguageDropdown, DefinitionSourceDropdown,
    OMPI_DEFS_SOURCES
} from '../../common/dropdowns'

export default class SearchForm extends Component {
    static propTypes = {
        query: PropTypes.string,
        classNumber: PropTypes.number,
        language: PropTypes.string,
        sources: PropTypes.array,
        submitIndexSearch: PropTypes.func,
    }

    static defaultProps = {
        query: '',
        language: 'english',
        classNumber: undefined,
        sources: ['niza10'],
    }

    constructor(props) {
        super(props)

        this.state = {
            query: this.props.query,
            classNumber: this.props.classNumber,
            language: this.props.language,
            sources: Array.isArray(this.props.sources) ? this.props.sources.slice() : [],
        }
    }

    updateSearchLanguage = (language) => {
        this.setState({language})
    }

    onUpdateQuery = (event) => {
        this.setState({query: event.target.value})
    }

    onUpdateSources = (sources) => {
        this.setState({sources: sources.slice()})
    }

    onUpdateClassNumber = (classNumber) => {
        this.setState({classNumber})
    }

    submitForm = (e) => {
        e.preventDefault()
        this.props.submitIndexSearch(this.state)
    }

    render() {
        return (
            <Form horizontal={true} onSubmit={this.submitForm}>
                <InputGroup bsSize={"sm"}>
                    <InputGroup.Button>
                        <LanguageDropdown
                            language={this.state.language}
                            onChange={this.updateSearchLanguage}
                            label=""
                            bsSize="small"
                            header="Idioma de Búsqueda"
                        />
                    </InputGroup.Button>

                    <InputGroup bsSize={"sm"} style={{width:'100%'}}>
                        <FormControl
                            placeholder="Buscar..."
                            onChange={this.onUpdateQuery}
                            style={{borderRadius: '0px', borderLeft: 'none'}}
                        />
                    </InputGroup>

                    <InputGroup.Button>
                        <DefinitionSourceDropdown
                            bsSize="small"
                            pullRight={true}
                            selectedClass={this.state.classNumber}
                            label=""
                            sources={["niza10"]}
                            availableSources={OMPI_DEFS_SOURCES}
                            onChange={this.onUpdateSources}
                            header="Buscar en Índices"
                        />

                        <ClassesDropdown
                            bsSize="small"
                            pullRight={true}
                            selectedClass={this.state.classNumber}
                            label=""
                            onChange={this.onUpdateClassNumber}
                        />
                        <Button type={"submit"} default={true}>Buscar</Button>
                    </InputGroup.Button>
                </InputGroup>
            </Form>
        )
    }
}
