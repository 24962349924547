import React from 'react'
import PropTypes from 'prop-types'
import { ToggleButtonGroup, ToggleButton } from 'react-bootstrap';

const ALL_CLASS_NUMBERS = []
for (var i = 1; i <= 45; i++) {
    ALL_CLASS_NUMBERS.push(i)
}

export const ClassNumberToggler = (props) => (
    <ToggleButtonGroup
        className="class-numbers-container"
        bsSize={"md"}
        type={"radio"}
        name="classNumber"
        onChange={props.onToggleClass}
        style={props.style}>
        {
            ALL_CLASS_NUMBERS.map((classNumber, i) => {
                return (
                    <ToggleButton value={classNumber} key={i}
                        style={{minWidth: `${props.btnRadio}%`}}>
                        <h5>{`0${classNumber}`.slice(-2)}</h5>
                    </ToggleButton>
                )
            })
        }

        {
            props.displayResetButton && (
                <ToggleButton value={0} key={45} style={{minWidth: "100%"}}>
                    <h5 className="text-danger text-bold">{ props.resetButtonLabel || "Todas las Clases"}</h5>
                </ToggleButton>
            )
        }
    </ToggleButtonGroup>
)

ClassNumberToggler.propTypes = {
    onToggleClass: PropTypes.func.isRequired,
    btnRadio: PropTypes.number
}

ClassNumberToggler.defaultProps = {
    btnRadio: 20,
}
