import {connect} from 'react-redux'
import DefinitionsTranslation from '../components/translation'

const reduxStateToPropsMapper = ({translations}) => {
    return {
        key: translations.requestId,
        classFilter: translations.params.classFilter,
        indications: translations.params.indications,
        sources: translations.params.sources,
        from: translations.params.from,
        to: translations.params.to,
        translations: translations.translations,
        fetching: translations.fetching,
        error: translations.error
    }
}


const translationEventsToReduxActionsMapper = ({translations}) => {
    return {
        onDoTranslation: (translationSettings) => {
            translations.setTranslationParams(translationSettings)
            translations.performTranslation()
        }
    }
}


const IndicationsTranslator = connect(
    reduxStateToPropsMapper,
    translationEventsToReduxActionsMapper
)(DefinitionsTranslation)


export default IndicationsTranslator
